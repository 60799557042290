/** 
 *   预售
 */
import { get } from '@/untils/js/axios.js'

// 获取热门新品预售
export const getHotPreSale = (id,params) => get(`/goods/pre-sale/${id}`, params)

// 获取限量好货
export const getLimitPreSale = (id,params) => get(`/goods/pre-sale/${id}`, params)

// 获取预售分类
export const getPreSaleClassify = params => get('/goods/pre-sale-type', params)

// 获取预售分类下的商品
export const getPreSaleClassifyGoods = (id,params) => get(`/goods/pre-sale-list/${id}`, params)

// 获取预售Banner
export const getPreSaleBanner = id => get(`/special/banner-list/${id}`)

